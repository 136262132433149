import { IResultDetail } from '@app/api/result.api';
import { Descriptions } from 'antd';
import { Table } from 'components/common/Table/Table';
import { useState } from 'react';

interface SpeakReadAloudProps {
  data: IResultDetail;
}

export const WriteEssay: React.FC<SpeakReadAloudProps> = ({ data }) => {
  const [columns, setColumns] = useState([
    { title: 'Content', dataIndex: 'content', key: 'content' },
    { title: 'Form', dataIndex: 'form', key: 'form' },
    { title: 'Grammar', dataIndex: 'grammar', key: 'grammar' },
    { title: 'Vocabulary', dataIndex: 'vocabulary', key: 'vocabulary' },
    { title: 'General', dataIndex: 'general', key: 'general' },
    { title: 'Structure', dataIndex: 'structure', key: 'structure' },
    { title: 'Total', dataIndex: 'total', key: 'total' },
  ]);
  const [dataScore, setDataScore] = useState([{ key: 1, content: 0, pronunciation: 0, fluency: 0, total: 0 }]);

  return (
    <Descriptions bordered column={1} title="Question Details" size="small">
      <Descriptions.Item label="Question">{data.text}</Descriptions.Item>
      <Descriptions.Item label="Your Answer">{data.your_answer}</Descriptions.Item>
      <Descriptions.Item label="Score">
        <Table columns={columns} dataSource={dataScore} scroll={{ x: 800 }} pagination={false} />
      </Descriptions.Item>
    </Descriptions>
  );
};
