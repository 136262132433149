import { IResultDetail } from '@app/api/result.api';
import { Descriptions } from 'antd';
import { Table } from 'components/common/Table/Table';
import { useState } from 'react';

interface SpeakReadAloudProps {
  data: IResultDetail;
}

export const ListenDictation: React.FC<SpeakReadAloudProps> = ({ data }) => {
  const [columns, setColumns] = useState([{ title: 'Total', dataIndex: 'total', key: 'total' }]);
  const [dataScore, setDataScore] = useState([{ key: 1, total: data.score }]);

  return (
    <Descriptions bordered column={1} title="Question Details" size="small">
      <Descriptions.Item label="Correct answer">{data.correct_answer}</Descriptions.Item>
      <Descriptions.Item label="Your Answer">{data.your_answer}</Descriptions.Item>
      <Descriptions.Item label="Score">
        <Table columns={columns} dataSource={dataScore} pagination={false} />
      </Descriptions.Item>
    </Descriptions>
  );
};
