import { IResultDetail } from '@app/api/result.api';
import { Descriptions } from 'antd';
import { Table } from 'components/common/Table/Table';
import { useState } from 'react';

interface SpeakReadAloudProps {
  data: IResultDetail;
}

export const SpeakReadAloud: React.FC<SpeakReadAloudProps> = ({ data }) => {
  const [columns, setColumns] = useState([
    { title: 'Content', dataIndex: 'content', key: 'content' },
    { title: 'Pronunciation', dataIndex: 'pronunciation', key: 'pronunciation' },
    { title: 'Fluency', dataIndex: 'fluency', key: 'fluency' },
    { title: 'Total', dataIndex: 'total', key: 'total' },
  ]);
  const [dataScore, setDataScore] = useState([{ key: 1, content: 0, pronunciation: 0, fluency: 0, total: 0 }]);

  return (
    <Descriptions bordered column={1} title="Question Details" size="small">
      <Descriptions.Item label="Correct answer">{data.text}</Descriptions.Item>
      <Descriptions.Item label="Your Answer">
        <audio controls crossOrigin="anonymous">
          <source src={`${process.env.REACT_APP_BASE_URL}${data.audio}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </Descriptions.Item>
      <Descriptions.Item label="Score">
        <Table columns={columns} dataSource={dataScore} scroll={{ x: 800 }} pagination={false} />
      </Descriptions.Item>
    </Descriptions>
  );
};
