import styled from 'styled-components';

export const Header = styled.div`
  background: #fae7a5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.375rem;
`;
export const Wrapper = styled.div`
  background: #fff;
  color: #333;
  padding: 25px 20px;
  max-width: 72rem;
  margin: 2rem auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
`;

export const FadeInRight = styled.div`
  animation-delay: 0ms;
  animation-duration: 1000ms;
  pointer-events: all;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  // -webkit-animation-fill-mode: both;
  // animation-fill-mode: both;

  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
`;

export const BtnFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;
