import { Button, Tag } from 'antd';
import { Table } from 'components/common/Table/Table';
import { Modal } from '@app/components/common/Modal/Modal';
import * as S from './ExamMockTestInfo.styles';
import { useEffect, useState } from 'react';
import { ReactComponent as MockIcon } from '@app/assets/icons/mock.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExamMockTestResult } from '../ExamMockTestResult';
import { notificationController } from '@app/controllers/notificationController';
import { getMockTestsHis, IMockTest, IMockTestHis, IMockTestUserRequest, mockTestCancel } from '@app/api/mockTest.api';
import { MOCK_TEST_USER_STATUS } from '@app/constants/mockTest';
import { ExamMockTestTable } from './ExamMockTestTable';

export const ExamMockTestInfo: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mockTestId = queryParams.get('id');
  const [isModalResume, setIsModalResume] = useState<boolean>(false);
  const [isModalStart, setIsModalStart] = useState<boolean>(false);
  const [result, setResult] = useState(false);

  const [data, setData] = useState<IMockTestHis[]>([]);
  const [currentMockTest, setCurrentMockTest] = useState<IMockTestHis>();
  const [detailMockTest, setDetailMockTest] = useState<IMockTest>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMockTestHis();
  }, [mockTestId]);

  const fetchMockTestHis = async () => {
    try {
      if (!mockTestId) return;
      const { mockTest, userMockTest } = await getMockTestsHis(mockTestId);
      setData(userMockTest);
      setDetailMockTest(mockTest);
      if (userMockTest.length > 0) setCurrentMockTest(userMockTest[0]);
    } catch (error) {
      notificationController.error({
        message: 'Xảy ra lỗi. Vui lòng thử lại sau.',
      });
    }
  };

  const handleResumeClick = () => {
    navigate(`/exam/mock-test/detail?id=${mockTestId}`);
  };

  const handleStartClick = () => {
    console.log(`Test with id clicked`);
    navigate(`/exam/mock-test/intro?id=${mockTestId}`);
  };

  const handleResultClick = (id: string) => {
    navigate(`/result/${id}`);
  };

  const handleReset = async () => {
    try {
      if (!mockTestId) return;

      const payload: IMockTestUserRequest = { mockTestId };
      await mockTestCancel(payload);
      setIsModalResume(false);
      fetchMockTestHis();
    } catch (error) {
      notificationController.error({
        message: 'Xảy ra lỗi. Vui lòng thử lại sau.',
      });
    }
  };

  return (
    <>
      {!result ? (
        <>
          <div className="flex flex-col rounded-lg bg-white p-8">
            <S.Title level={3}>{detailMockTest?.name}</S.Title>
            <S.Wrapper>
              <S.Column>
                <p>
                  Q3S Scored Mock Test will take approximately 2 hours. The test questions are collected from the real
                  exams. Upon completion the candidate might check the scores breakdown.
                </p>
                {currentMockTest && currentMockTest.status == MOCK_TEST_USER_STATUS.IN_PROGRESS ? (
                  <Button className="mt-4" type="primary" onClick={() => setIsModalResume(true)}>
                    Continue
                  </Button>
                ) : (
                  <Button className="mt-4" type="primary" onClick={() => setIsModalStart(true)}>
                    Start Now
                  </Button>
                )}
              </S.Column>
              <S.Column>
                <p>Communicative scores:</p>
                <S.Ul>
                  <li>Speaking</li>
                  <li>Writing</li>
                  <li>Reading</li>
                  <li>Listening</li>
                </S.Ul>
                <p>Enabling scores:</p>
                <S.Ul>
                  <li>Grammar</li>
                  <li>Pronunciation</li>
                  <li>Fluency</li>
                  <li>Vocabulary</li>
                  <li>Written Discourse</li>
                  <li>Spelling</li>
                </S.Ul>
              </S.Column>
            </S.Wrapper>
            <div>
              <p>{detailMockTest?.description}</p>
              {/* <p>
                This AI practice test comprises of 4 modules in PTE Academic: Speaking, Writing, Reading and Listening.
                There should be 57 questions and test timing is set in the analogous pattern as the actual test, which
                would help the candidates to know what to expect on the test day and check their skills level.
              </p>
              <p>
                The test replicates the real exam format and upon completion, a full score report will be generated. The
                test-taker should be able to check their answers to identify areas for improvement.
              </p> */}
            </div>
          </div>
          <div className="flex flex-col rounded-lg bg-white p-8 mt-4">
            <S.Title level={3}>Mock test history</S.Title>
            <ExamMockTestTable
              mockTestHis={data}
              onContinue={() => setIsModalResume(true)}
              onResult={handleResultClick}
            />
          </div>
        </>
      ) : (
        <ExamMockTestResult />
      )}

      {/* modal confirm start  */}
      <Modal
        title={`Start a new exam`}
        visible={isModalStart}
        onOk={handleStartClick}
        onCancel={() => setIsModalStart(false)}
      >
        <p>{currentMockTest?.name}</p>
        <p>{`Are you sure to start new Mock Test?`}</p>
      </Modal>

      {/* Modal confirm resune */}
      <Modal
        title={`Resume your exam`}
        visible={isModalResume}
        onOk={handleResumeClick}
        onCancel={handleReset}
        okText="Resume"
        cancelText="Reset this mock test"
      >
        <p>{currentMockTest?.name}</p>
        <p>{`You can resume this Mock Test!`}</p>
      </Modal>
    </>
  );
};
