import { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import * as S from './ExamMockTestDetail.styles';
import { notificationController } from '@app/controllers/notificationController';
import { IMockTestUserRequest, mockTestFinish, mockTestResume, saveProgressMockTest } from '@app/api/mockTest.api';
import { RenderQuestion } from '../../renderQuestion';
import { IExam } from '@app/api/academic.api';
import CountdownTimer from './CountdownTimer';
import { ExamMockTestFinish } from '../ExamMockTestFinish';
import { ExamMockTestBreak } from '../ExamMockTestBreak';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertTypeParctice } from '@app/utils/utils';

const STEP_DURATIONS: Record<string, number> = {
  SPEAKING: 1800,
  WRITING: 1800,
  LISTENING: 1980,
  READING: 1800,
  BREAK1: 120,
  BREAK2: 120,
};
const STEPS = ['SPEAKING', 'WRITING', 'BREAK1', 'READING', 'BREAK2', 'LISTENING'];

export const ExamMockTestDetail: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mockTestId = queryParams.get('id');
  const isFirstRender = useRef(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [questions, setQuestions] = useState<IExam[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [step, setStep] = useState('SPEAKING');
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState<number | null>(null);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [currentAnswer, setCurrentAnswer] = useState<string | Blob | null>(null);

  const handleGetTime = (time: number) => {
    setCurrentTime(time);
  };

  useEffect(() => {
    if (!mockTestId) return;
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const res = await mockTestResume(mockTestId);
        const { questions, userMockTest } = res;
        setQuestions(questions);

        const questionType = res.questions.filter((q) => q.type.startsWith(res.userMockTest.step));

        const isLastQuestion = questionType.length === userMockTest.currentquestion;

        //next step when last question
        if (isLastQuestion) {
          const nextStepIndex = STEPS.indexOf(res.userMockTest.step) + 1;
          setCurrentIndex(0);
          setStep(STEPS[nextStepIndex]);
          setTimeLeft(STEP_DURATIONS[STEPS[nextStepIndex]]);
        } else {
          setCurrentIndex(res.userMockTest.currentquestion);
          setStep(res.userMockTest.step);
          setTimeLeft(res.userMockTest.remaintime);
        }
      } catch (error) {
        notificationController.error({ message: `Xảy ra lỗi. Vui lòng thử lại sau` });
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [mockTestId]);

  const currentQuestions = questions.filter((q) => q.type.startsWith(step));
  const currentIndexView = questions.findIndex((item) => item.id === currentQuestions[currentIndex]?.id);

  const handleTimeout = () => {
    setTimeout(() => {
      setIsModalVisible(false);
      setCurrentAnswer(null);
      const nextStepIndex = STEPS.indexOf(step) + 1;

      if (nextStepIndex < STEPS.length) {
        setStep(STEPS[nextStepIndex]);
        setCurrentIndex(0);
        setTimeLeft(STEP_DURATIONS[STEPS[nextStepIndex]]);
      } else {
        setStep('FINISH');
        onFinished();
      }
    }, 1000);
  };

  const handleGotoNext = async () => {
    if (!mockTestId) return;

    try {
      setIsModalVisible(false);

      // const payload = {
      //   mockTestId,
      //   questionId: currentQuestions[currentIndex]?.id,
      //   questionType: currentQuestions[currentIndex]?.type,
      //   answer: currentAnswer,
      //   currentQuestion: currentIndex + 1,
      //   remainTime: currentTime,
      //   step,
      // };

      const formData = new FormData();
      formData.append('mockTestId', mockTestId);
      formData.append('questionId', currentQuestions[currentIndex]?.id);
      formData.append('questionType', currentQuestions[currentIndex]?.type);
      formData.append('currentQuestion', (currentIndex + 1).toString());
      formData.append('remainTime', currentTime?.toString() || '');
      formData.append('step', step);

      if (currentAnswer instanceof Blob) {
        console.log(currentAnswer);
        formData.append('file', currentAnswer);
        formData.append('answer', '');
      } else {
        formData.append('answer', currentAnswer || '');
      }

      //save current progress
      await saveProgressMockTest(formData);

      setCurrentAnswer(null);
      // Logical handling of question transfer
      if (currentIndex < currentQuestions.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        const nextStep = STEPS[STEPS.indexOf(step) + 1];

        if (nextStep) {
          setStep(nextStep);
          setCurrentIndex(0);
          setTimeLeft(STEP_DURATIONS[nextStep]);
        } else {
          handleTimeout();
        }
      }
    } catch (error) {
      notificationController.error({
        message: 'Xảy ra lỗi. Vui lòng thử lại sau',
      });
    }
  };

  const handleSkip = () => {
    const nextStep = STEPS[STEPS.indexOf(step) + 1];
    if (nextStep) {
      setStep(nextStep);
      setCurrentIndex(0);
      setTimeLeft(STEP_DURATIONS[nextStep]);
    }
  };

  const handleSaveExit = () => {
    navigate(`/exam/mock-test?id=${mockTestId}`);
  };

  const onFinished = async () => {
    try {
      if (!mockTestId) return;

      const payload: IMockTestUserRequest = { mockTestId };
      await mockTestFinish(payload);
    } catch (error) {
      notificationController.error({
        message: 'Xảy ra lỗi. Vui lòng thử lại sau',
      });
    }
  };

  const handleAnswerChange = (answer: string | Blob) => {
    // if (answer instanceof Blob) {
    //   const blobURL = URL.createObjectURL(answer);
    // }

    setCurrentAnswer(answer);
  };

  return (
    <>
      {step == 'FINISH' ? (
        <ExamMockTestFinish />
      ) : (
        <>
          <div className="flex items-center justify-end rounded-md bg-blue-500 p-2 text-xs text-white mb-2">
            <CountdownTimer initialTime={timeLeft} onTimeout={handleTimeout} onGetTime={handleGetTime} />
          </div>
          {currentQuestions.length > 0 && (
            <>
              <S.Header>
                <p className="text-base uppercase">{convertTypeParctice(currentQuestions[currentIndex]?.type)}</p>
                <div className="flex gap-2">
                  <p className="rounded-md bg-gray-400 p-2 text-sm text-white">
                    Question {currentIndexView + 1} of {questions.length}
                  </p>
                </div>
              </S.Header>
              <S.Wrapper>
                <S.FadeInRight key={currentIndex}>
                  <RenderQuestion
                    type={currentQuestions[currentIndex]?.type}
                    currentQuestion={currentQuestions[currentIndex]}
                    showAnswer={false}
                    isFirstRender={isFirstRender}
                    onAnswerChange={handleAnswerChange}
                  />
                </S.FadeInRight>
              </S.Wrapper>

              <S.BtnFooter>
                <Button type="primary" onClick={handleSaveExit}>
                  Save and Exit
                </Button>
                <Button type="primary" onClick={() => setIsModalVisible(true)}>
                  Next
                </Button>
              </S.BtnFooter>
            </>
          )}

          {(step == 'BREAK1' || step == 'BREAK2') && <ExamMockTestBreak onSkip={handleSkip} />}
        </>
      )}

      <Modal
        title={`Keep Moving`}
        visible={isModalVisible}
        onOk={handleGotoNext}
        onCancel={() => setIsModalVisible(false)}
      >
        <p>{`Are you sure you want to move to the next question?`}</p>
      </Modal>
    </>
  );
};
