import { Tabs, TabPane } from '@app/components/common/Tabs/Tabs';
import * as S from './ResultDetailPage.styles';
import { useEffect, useState } from 'react';
import { Tag, TableProps } from 'antd';
import { CopyFilled, EyeOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { statusType } from '@app/utils/utils';
import { getResultDetail, IResultDetail } from '@app/api/result.api';
import { notificationController } from '@app/controllers/notificationController';
import { TableCollapse } from './TableCollapse';
import { convertTypeParctice } from '@app/utils/utils';

export const ResultDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState<TableProps<IResultDetail>['columns']>([
    { title: '#', dataIndex: 'index', key: 'index', render: (_: any, __: any, index: number) => index + 1 },
    {
      title: 'Type',
      dataIndex: 'questionType',
      key: 'questionType',
      render: (questionType: string) => convertTypeParctice(questionType),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={statusType[status]} key={status}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Total Score',
      dataIndex: 'score',
      key: 'score',
    },
    // {
    //   title: 'Actions',
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   render: (_: unknown, record: IResultDetail) => (
    //     <div className="flex gap-2">
    //       <Button
    //         type="primary"
    //         size="small"
    //         icon={<EyeOutlined />}
    //         onClick={() => handleViewDetails(record.questionId)}
    //       />
    //       <Button type="default" size="small" icon={<CopyFilled />} />
    //     </div>
    //   ),
    // },
  ]);
  // const [data, setData] = useState<IDataType[]>([
  //   {
  //     key: '1',
  //     name: 'John Doe',
  //     total: 100,
  //     type: 'Speaking',
  //     question: 'What is 2+2?',
  //     answer: '4',
  //     marks: 5,
  //     status: 'Pending',
  //     questionType: 'SPEAKING_READ_ALOUD',
  //   },
  //   {
  //     key: '2',
  //     name: 'Jane Smith',
  //     total: 100,
  //     type: 'Speaking',
  //     question: 'What is the capital of France?',
  //     answer: 'Paris',
  //     marks: 10,
  //     status: 'Done',
  //     questionType: 'SPEAKING_READ_ALOUD',
  //   },
  // ]);
  const [resultLists, setResultLists] = useState<{
    speakingList: IResultDetail[];
    writingList: IResultDetail[];
    readingList: IResultDetail[];
    listeningList: IResultDetail[];
  }>({
    speakingList: [],
    writingList: [],
    readingList: [],
    listeningList: [],
  });

  useEffect(() => {
    fetchMockTestResult();
  }, [id]);

  const fetchMockTestResult = async () => {
    if (!id) return;
    try {
      setLoading(true);

      const res = await getResultDetail(id);

      const groupedResults = res.reduce(
        (acc, item) => {
          if (item.questionType.startsWith('SPEAKING')) {
            acc.speakingList.push(item);
          } else if (item.questionType.startsWith('WRITING')) {
            acc.writingList.push(item);
          } else if (item.questionType.startsWith('READING')) {
            acc.readingList.push(item);
          } else if (item.questionType.startsWith('LISTENING')) {
            acc.listeningList.push(item);
          }
          return acc;
        },
        { speakingList: [], writingList: [], readingList: [], listeningList: [] } as {
          speakingList: IResultDetail[];
          writingList: IResultDetail[];
          readingList: IResultDetail[];
          listeningList: IResultDetail[];
        },
      );

      setResultLists(groupedResults);
    } catch (error) {
      notificationController.error({
        message: 'Xảy ra lỗi. Vui lòng thử lại sau.',
      });
    }
    setLoading(false);
  };

  const handleViewDetails = (id: string) => {
    navigate(`/mock-test/${id}`);
  };

  return (
    <div className="flex flex-col rounded-lg bg-white p-8">
      <S.Title level={3}>Detail Test</S.Title>

      <Tabs defaultActiveKey="1">
        <TabPane tab={`Speaking`} key="1">
          <TableCollapse columns={columns} dataSource={resultLists.speakingList} />
        </TabPane>
        <TabPane tab={`Writing`} key="2">
          <TableCollapse columns={columns} dataSource={resultLists.writingList} />
        </TabPane>
        <TabPane tab={`Reading`} key="3">
          <TableCollapse columns={columns} dataSource={resultLists.readingList} />
        </TabPane>
        <TabPane tab={`Listening`} key="4">
          <TableCollapse columns={columns} dataSource={resultLists.listeningList} />
        </TabPane>
      </Tabs>
    </div>
  );
};
