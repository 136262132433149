import { httpApi } from './http.api';

export interface IResult {
  UserMockTestId: string;
  date: string;
  listening: string;
  reading: string;
  speaking: string;
  writing: string;
  score: number;
  status: string;
  name: string;
}

export interface IResultDetail {
  audio: string;
  choices: string;
  correct_answer: string;
  description: string;
  image_url: string;
  mockTestId: string;
  phrase_hints: string;
  questionId: string;
  questionType: string;
  score: number;
  status: string;
  text: string;
  your_answer: string;
}

export const getMockTestsResult = (): Promise<IResult[]> => {
  return httpApi.get<IResult[]>(`/results`).then(({ data }) => data);
};

export const getResultDetail = (mockTestUserId: string): Promise<IResultDetail[]> => {
  return httpApi.get<IResultDetail[]>(`/results/${mockTestUserId}`).then(({ data }) => data);
};
