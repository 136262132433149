import { useNavigate } from 'react-router-dom';
import { Table } from 'components/common/Table/Table';
import * as S from './ResultPage.styles';
import { useEffect, useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { EyeOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { getMockTestsResult, IResult } from '@app/api/result.api';

export const ResultPage: React.FC = () => {
  const navigate = useNavigate();
  const [columns, setColumns] = useState([
    { title: '#', dataIndex: 'index', key: 'index', render: (_: any, __: any, index: number) => index + 1 },
    { title: 'Mock Name', dataIndex: 'name' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        const statusColors: Record<string, string> = {
          DONE: 'success',
          IN_PROGRESS: 'processing',
          CANCELLED: 'error',
          FINISHED: 'warning',
        };

        return (
          <Tag color={statusColors[status] || 'default'} key={status}>
            {status}
          </Tag>
        );
      },
    },
    { title: 'Speaking', dataIndex: 'speaking' },
    { title: 'Writing', dataIndex: 'writing' },
    { title: 'Reading', dataIndex: 'reading' },
    { title: 'Listening', dataIndex: 'listening' },
    { title: 'Score', dataIndex: 'score' },
    { title: 'CreatedDate', dataIndex: 'date' },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { name: string; UserMockTestId: string }) => {
        return (
          <Button
            type="primary"
            size="small"
            icon={<EyeOutlined />}
            onClick={() => handleViewDetails(record.UserMockTestId)}
          ></Button>
        );
      },
    },
  ]);
  const [data, setData] = useState<IResult[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMockTestResult();
  }, []);

  const fetchMockTestResult = async () => {
    try {
      setLoading(true);
      const res = await getMockTestsResult();
      setData(res);
    } catch (error) {
      notificationController.error({
        message: 'Xảy ra lỗi. Vui lòng thử lại sau.',
      });
    }
    setLoading(false);
  };

  const handleViewDetails = (id: string) => {
    navigate(`/result/${id}`);
  };

  return (
    <div className="flex flex-col rounded-lg bg-white p-8">
      <S.Title level={3}>Result</S.Title>
      <div>
        <p className="mt-2 text-sm">This is the result page. Here you can see the result of the test.</p>

        <Table
          columns={columns}
          dataSource={data}
          rowKey="UserMockTestId"
          loading={loading}
          scroll={{ x: 800 }}
          bordered
        />
      </div>
    </div>
  );
};
