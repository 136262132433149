import { IResultDetail } from '@app/api/result.api';
import { QUESTIONS } from '@app/constants/questions';
import { Descriptions, Table, TableProps } from 'antd';
import { SpeakReadAloud } from '../UIType/SpeakReadAloud';
import { SpeakRepeatSentence } from '../UIType/SpeakRepeatSentence';
import { SpeakDescribeImage } from '../UIType/SpeakDescribeImage';
import { SpeakRetelllecture } from '../UIType/SpeakRetelllecture';
import { WriteText } from '../UIType/WriteText';
import { WriteEssay } from '../UIType/WriteEssay';
import { ReadFIBRW } from '../UIType/ReadFIBRW';
import { ListenText } from '../UIType/ListenText';
import { ReadParagraph } from '../UIType/ReadParagraph';
import { ListenDictation } from '../UIType/ListenDictation';
import { SpeakShortQuestion } from '../UIType/SpeakShortQuestion';
import { ListenMultiAns } from '../UIType/ListenMultiAns';
import { ListenFIBL } from '../UIType/ListenFIBL';
import { ListenSingleAns } from '../UIType/ListenSingleAns';
import { ListenIncorrectWord } from '../UIType/ListenIncorrectWord';

interface TableCollapseProps<RecordType> {
  columns: TableProps<RecordType>['columns'];
  dataSource: RecordType[];
}

const COMPONENT_MAP = {
  [QUESTIONS.SPEAKING.READ_ALOUD]: SpeakReadAloud,
  [QUESTIONS.SPEAKING.REPEAT_SENTENCE]: SpeakRepeatSentence,
  [QUESTIONS.SPEAKING.DESCRIBE_IMAGE]: SpeakDescribeImage,
  [QUESTIONS.SPEAKING.ANSWER_SHORT_QUESTION]: SpeakShortQuestion,
  [QUESTIONS.SPEAKING.RETELL_LECTURE]: SpeakRetelllecture,
  [QUESTIONS.WRITING.SUMMARIZE_WRITTEN_TEXT]: WriteText,
  [QUESTIONS.WRITING.WRITING_ESSAY]: WriteEssay,
  [QUESTIONS.READING.FIB_R_W]: ReadFIBRW,
  [QUESTIONS.READING.MCQ_R_MULTIPLE_ANSWER]: ReadFIBRW,
  [QUESTIONS.READING.RE_ORDER_PARAGRAPH]: ReadParagraph,
  [QUESTIONS.READING.FIB_R]: ReadFIBRW,
  [QUESTIONS.READING.MCQ_R_SINGLE_ANSWER]: ReadFIBRW,
  [QUESTIONS.LISTENING.SUMMARIZE_SPOKEN_TEXT]: ListenText,
  [QUESTIONS.LISTENING.MCQ_L_MULTIPLE_ANSWER]: ListenMultiAns,
  [QUESTIONS.LISTENING.FIB_L]: ListenFIBL,
  [QUESTIONS.LISTENING.HIGHLIGHT_CORRECT_SUMMARY]: ReadFIBRW,
  [QUESTIONS.LISTENING.MCQ_L_SINGLE_ANSWER]: ListenSingleAns,
  [QUESTIONS.LISTENING.SELECT_MISSING_WORD]: ReadFIBRW,
  [QUESTIONS.LISTENING.HIGHLIGHT_INCORRECT_WORD]: ListenIncorrectWord,
  [QUESTIONS.LISTENING.DICTATION]: ListenDictation,
};

export const TableCollapse: React.FC<TableCollapseProps<IResultDetail>> = ({ columns, dataSource, ...rest }) => {
  const getExpandedRowContent = (record: IResultDetail) => {
    const Component = COMPONENT_MAP[record.questionType];
    return Component ? <Component data={record} /> : null;
  };

  return (
    <Table
      expandable={{
        expandedRowRender: (record) => getExpandedRowContent(record),
        rowExpandable: (record) => record.questionId !== 'Not Expandable',
        expandRowByClick: true,
        defaultExpandAllRows: false,
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowKey="questionId"
      {...rest}
    />
  );
};
